<template>
  <div class="component horizontal-padding">
    <h1>Documents</h1>

    <spinner
      v-if="status === 'loading'"
      preset="large"
    />

    <template v-else-if="status === 'error'">
      <alert variant="danger">
        An error occurred loading the documents. Please check your connection
        and try again.
      </alert>

      <button
        type="button"
        class="btn btn-outline-primary"
        @click="loadDocuments"
      >
        Try Again
      </button>
    </template>

    <ul v-else-if="status === 'loaded'">
      <li
        v-for="document of documents"
        :key="`document${document.slug}`"
      >
        <router-link :to="{ name: 'document', params: { documentSlug: document.slug } }">
          {{ document.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    status: 'loading' as 'loading' | 'loaded' | 'error',
    documents: [] as Array<{ title: string; slug: string; }>,
  }),
  created() {
    this.loadDocuments();
  },
  methods: {
    async loadDocuments() {
      this.status = 'loading';

      const responseData = await this.api({ url: 'business-portal/documents/' });

      if (responseData.status === 200) {
        this.documents = responseData.body;
        this.status = 'loaded';
      } else {
        this.status = 'error';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    margin: 0 auto;
    max-width: 500px;
  }

  ul {
    padding-left: 1em;
    list-style: none;
    text-indent: -1em;
    word-break: break-word;
  }
</style>
